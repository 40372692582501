/*
 * @Author: Coan
 * @Date: 2023-04-10 10:23:44
 * @LastEditors: Coan
 * @LastEditTime: 2023-05-24 04:02:39
 * @FilePath: /rebuild_web_new/src/router/index.js
 * @Description:
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 首页路由
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/caseCenter/insideCaseCente'),
  },
  // // 登陆路由
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/login'),
  // },
  // // 案例中心路由
  // {
  //   path: '/caseCenter',
  //   name: 'caseCenter',
  //   component: () => import('@/views/caseCenter/caseCenter'),
  // },
  // {
  //   path: '/insideCaseCente',
  //   name: 'insideCaseCente',
  //   component: () => import('@/views/caseCenter/insideCaseCente'),
  //   meta: {
  //     showFloatBtn: true
  //   }
  // },
  // // 新闻资讯
  // {
  //   path: '/newDetail',
  //   name: 'newDetail',
  //   component: () => import('@/views/Home/components/newDetail')
  // },
  // // 解决方案
  // {
  //   path: '/solution',
  //   name: 'solution',
  //   component: () => import('@/views/solution/solution'),
  // },
  // {
  //   path: '/solutionDetail',
  //   name: 'solutionDetail',
  //   component: () => import('@/views/solution/solutionDetail')
  // },
  // // 体验入口
  // {
  //   path: '/experience',
  //   name: 'experience',
  //   component: () => import('@/views/experience/index'),
  //   redirect: '/experience/selfPoj',
  //   meta: {
  //     title: '体验入口',
  //     showFloatBtn: true
  //   },
  //   children: [
  //     {
  //       path: 'dashBoard',
  //       name: 'dashBoard',
  //       component: () => import('@/views/experience/dashBoard'),
  //       meta: {
  //         title: '仪表盘',
  //         showFloatBtn: true
  //       }
  //     },
  //     {
  //       path: 'selfPoj',
  //       name: 'selfPoj',
  //       component: () => import('@/views/experience/selfPoj'),
  //       meta: {
  //         title: '我的项目',
  //         showFloatBtn: true
  //       }
  //     },
  //     {
  //       path: 'space',
  //       name: 'space',
  //       component: () => import('@/views/experience/space'),
  //       meta: {
  //         title: '个人中心',
  //         showFloatBtn: true
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/createModel',
  //   name: 'createModel',
  //   component: () => import('@/views/createModel/index.vue'),
  //   meta: {
  //     title: '创建模型',
  //     showFloatBtn: true
  //   }
  // },
  // // 联系我们
  // {
  //   path: '/contactUs',
  //   name: 'contactUs',
  //   component: () => import('@/views/contactUs/contactUs'),
  // }
]

const router = new VueRouter({
  mode: 'history',
  // mode: "hash",
  base: process.env.BASE_URL,
  routes
})

export default router
